import React, { useState, useContext, useEffect, useRef } from 'react';
import { DataContext } from "../contexts/DataContext";
import WeaponsHandicaps from '../comps/WeaponsHandicaps';
import '../css/styles.css';

const FuckYouUp = () => {
    const { weaponsDataValue, handicapsValue } = useContext(DataContext);
    const [handicaps, setHandicaps] = handicapsValue;
    const weaponsData = weaponsDataValue;
    const [playerAmount, setPlayerAmount] = useState(1);

   
    const [randIndex, setRandIndex] = useState([]);

    const playerAmountRef = useRef(null);

    const random = (length) => {
        return Math.floor(Math.random() * length);
    }

    const selectPlayerAmount = (e) => {
        setPlayerAmount(e.target.value);
    }

    const createRandomIndex = () => {
        let randI = [];
        for (let i = 0; i < playerAmount; i++) {
            const generated = random(weaponsData.length);
            randI = [...randI, generated];
        }
        // console.log(randI);
        setRandIndex(randI);
    }

    const mainWrapperStyle = {
        textAlign: "center"
    }

    useEffect(() => {
        setPlayerAmount(playerAmountRef.current.value);
    }, [])

    return (
        <div className="mainWrapper" style={mainWrapperStyle}>
            <div className="playerAmountWrapper">
                <h4>How many player is going to hunt?</h4>
                <select onChange={(e) => selectPlayerAmount(e)} ref={playerAmountRef} >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                </select>
            </div>
            <div className="weaponWrapper">
                <h4 className="weapon">Weapon(s)</h4>
                <button onClick={createRandomIndex}>Click to choose</button>
                <br />
                <br />
                <hr />
                {
                    randIndex.map((digit, i)=>(
                        <WeaponsHandicaps index={digit+1} key={i} />
                    ))
                }
            </div>
        </div>
    );
}

export default FuckYouUp;
