import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
// import DataInit from './pages/DataInit';
import FuckYouUp from './pages/FuckYouUp';
import { DataProvider } from './contexts/DataContext';

const App = () => {
  return (
    <div>
      <React.Fragment>
        <Router>
          <DataProvider>
            <Switch>
              {/* <Route path="/" exact component={DataInit} /> */}
              <Route path="/" exact component={FuckYouUp} />
            </Switch>
          </DataProvider>
        </Router>
      </React.Fragment>
    </div>
  )
}

export default App;
