import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from "../contexts/DataContext";
import '../css/styles.css';

const WeaponsHandicaps = ({ index }) => {

    const { weaponsDataValue, handicapsValue } = useContext(DataContext);
    const weaponsData = weaponsDataValue;
    const [handicaps, setHandicaps] = handicapsValue

    const [chosenWeapon, setChosenWeapon] = useState({});
    const [weaponHandicap, setWeaponHandicap] = useState();
    const [extraHandicaps, setextraHandicaps] = useState([]);

    const extraHandicapsAmount = 4;
    
    const random = (length) => {
        return Math.floor(Math.random() * length);
    }

    const init = () => {
        const fixIndex = index - 1;
        const data = weaponsData[fixIndex];
        const handicapData = data.handicaps[random(data.handicaps.length)];
        setChosenWeapon(data);
        setWeaponHandicap(handicapData);
    }

    const extraHandicapsProcess = () => {
        let index = [];
        let exhandicaps = [];
        while (true) {
            if (index.length >= extraHandicapsAmount) {
                break;
            }
            let randIndex = random(handicaps.length);
            // check if the array has the same index
            for (let i = 0; i < index.length; i++) {
                const ele = index[i];
                if(ele === randIndex) {
                    randIndex = random(handicaps.length)
                }
                break;
            }
            index = [...index, randIndex];
        }

        for (let i = 0; i < index.length; i++) {
            const ele = index[i];
            exhandicaps = [...exhandicaps, handicaps[ele]]
        }
        // console.log('handicaps :>> ', index);
        setextraHandicaps(exhandicaps);
    }

    useEffect(() => {
        if (index) {
            init();
            extraHandicapsProcess();
        }
    }, [index]);

    return (
        <div>
            <div className="weaponType">{chosenWeapon.type}</div>
            <br/>
            {weaponHandicap}
            <div className="handicapsWrapper">
                <h4>Extra Handicaps</h4>
                {
                    extraHandicaps.map((handicap,i)=>(
                        <div key={i}>
                            {handicap.title}
                        </div>
                    ))
                }
            </div>
            <hr/>
        </div>
    );
}

export default WeaponsHandicaps;
