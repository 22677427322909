import React, { createContext, useState } from 'react';
import { WeaponsData, Handicaps } from "../Data";

export const DataContext = createContext();

export const DataProvider = (props) => {

    const weaponsData = WeaponsData;
    const [handicaps, setHandicaps] = useState(Handicaps);
    const [handicapsProcessed, setHandicapsProcessed] = useState([]);

    return (
        <DataContext.Provider
            value={{
                weaponsDataValue : weaponsData,
                handicapsValue : [handicaps, setHandicaps],
                handicapsProcessedValue : [handicapsProcessed, setHandicapsProcessed]
            }}
        >
            {props.children}
        </DataContext.Provider>
    )
}