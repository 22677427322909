export const WeaponsData = [
    {
        type: "Great Sword",
        handicaps: [
            'None! You lucky bastard!',
            'No blocking in combat!',
            'Unsheath weapon whilst moving across area!',
            'No true charged slash allow!'
        ],
        x: 0
    },
    {
        type: "Dualblade",
        handicaps: [
            'None! You lucky bastard!',
            'No demon mode!',
            'No clutch claw attacks!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Sword & Shield",
        handicaps: [
            'None! You lucky bastard!',
            'Only shield tackle allow!',
            'Aerial attacks only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Longsword",
        handicaps: [
            'None! You lucky bastard!',
            'No helmbreaker allow!',
            'No spirit blades!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Chargeblade",
        handicaps: [
            'None! You lucky bastard!',
            'No SAED!',
            'Axe swings only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Hammer",
        handicaps: [
            'None! You lucky bastard!',
            'No bigbang combos!',
            'No clutch claw attacks!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Huntinghorn",
        handicaps: [
            'None! You lucky bastard!',
            'Not allow to cast any melody!',
            'Only attack with R2!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Lance",
        handicaps: [
            'None! You lucky bastard!',
            'Only counter-thrusts allow!',
            'Can only attack with forward charging!',
            'Unsheath weapon whilst moving across area and prohibition of charging forward!'
        ],
        x: 0
    },
    {
        type: "Gunlance",
        handicaps: [
            'None! You lucky bastard!',
            'No phials attack!',
            'Can only attack with wyvern blast!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Switchaxe",
        handicaps: [
            'None! You lucky bastard!',
            'Axe swings only!',
            'No clutch claw attacks!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Insectglaive",
        handicaps: [
            'None! You lucky bastard!',
            'Not allow to collect extracts!',
            'Aerial attacks only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Bow",
        handicaps: [
            'None! You lucky bastard!',
            'Prohibition of using power coating!',
            'Strictly Normal coating only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Lightbowgun",
        handicaps: [
            'None! You lucky bastard!',
            'Prohibition of crafting ammo!',
            'Strictly Normal ammo only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
    {
        type: "Heavybowgun",
        handicaps: [
            'None! You lucky bastard!',
            'Prohibition of crafting ammo!',
            'Strictly Normal ammo only!',
            'Unsheath weapon whilst moving across area!'
        ],
        x: 0
    },
]

// normal, deco, deco_level, armour ,weapon

export const Handicaps = [
    {
        type: 'normal',
        title: 'NIL, N/A, NADAA!',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'A chance to void 1 of all handicaps',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'Remove all HUD',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'No felyne canteen food',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'No fast travel',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'No max potion and ancient max potion',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'No sharpening/craftable ammo',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'No mantles',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'Prohibition of using clutch claw',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'Can only heal using life powders/dust of life',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'Unfavorable element/status weapon has to be used',
        no_stack: 'false'
    },
    {
        type: 'normal',
        title: 'Can only attack the tail even after its severed',
        no_stack: 'false'
    },
    {
        type: 'deco',
        title: 'Remove all decorations',
        no_stack: 'true'
    },
    {
        type: 'deco',
        title: 'Remove all health boost decorations',
        no_stack: 'false'
    },
    {
        type: 'deco',
        title: 'Remove all weakness exploit decoratons',
        no_stack: 'false'
    },
    {
        type: 'deco',
        title: 'Remove all critical boost decorations',
        no_stack: 'false'
    },
    {
        type: 'deco',
        title: 'Remove all artillery decorations',
        no_stack: 'false'
    },
    {
        type: 'deco',
        title: 'Remove all agitator decorations',
        no_stack: 'false'
    },
    {
        type:'deco_level',
        title: 'Only level 1 decorations allow',
        no_stack: 'true'
    },
    {
        type:'deco_level',
        title: 'Only level 2 or below decorations allow',
        no_stack: 'true'
    },
    {
        type:'deco_level',
        title: 'Only level 3 or below decorations allow',
        no_stack: 'true'
    },
    {
        type: 'armour',
        title: 'Remove charm only',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove head armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove body armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove arm armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove waist armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove leg armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove charm and head armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove charm and armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove charm and arm armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove charm and waist armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove charm and leg armour piece',
        no_stack: 'false'
    },
    {
        type: 'armour',
        title: 'Remove 1 armour piece of choice', 
        no_stack: 'true'
    },
    {
        type: 'armour',
        title: 'Remove 2 armour pieces of choice',
        no_stack: 'true'
    },
    {
        type: 'weapon',
        title: 'Use a Rarity 12 weapon with no augmentations/upgrades',
        no_stack: 'true'
    },
    {
        type: 'weapon',
        title: 'Use a Rarity 11 weapon with no augmentations/upgrades',
        no_stack: 'true'
    },
    {
        type: 'weapon',
        title: 'Use a Rarity 10 weapon with no augmentations/upgrades',
        no_stack: 'true'
    },
];


/*
'NIL, N/A, NADAA!',
'A chance to void 1 of all handicaps',
'Remove all HUD',
'Remove all decorations',
'Remove all health boost decorations',
'Remove all weakness exploit decoratons',
'Remove all critical boost decorations',
'Remove all artillery decorations',
'Remove all agitator decorations',
'No felyne canteen food',
'No fast travel',
'No max potion and ancient max potion',
'No sharpening/craftable ammo',
'No mantles',
'Prohibition of using clutch claw',
'Can only heal using life powders/dust of life',
'Unfavorable element/status weapon has to be used',
'Can only attack the tail even after its severed',
'Remove charm and head armour piece',
'Remove charm and body armour piece',
'Remove charm and arm armour piece',
'Remove charm and waist armour piece',
'Remove charm and leg armour piece',
'Remove head armour piece',
'Remove body armour piece',
'Remove arm armour piece',
'Remove waist armour piece',
'Remove leg armour piece',
'Remove 1 armour piece of choice',        //does not stack with remove specific armour pieces handicaps
'Remove 2 armour pieces of choice',       //does not stack with remove specific armour pieces handicaps
'Only level 1 decorations allow',
'Only level 2 or below decorations allow',
'Only level 3 or below decorations allow',
'Use a Rarity 12 weapon with no augmentations/upgrades',
'Use a Rarity 11 weapon with no augmentations/upgrades',
'Use a Rarity 10 weapon with no augmentations/upgrades'
*/